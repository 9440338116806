import React, { useState, useRef, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ObserverSection from '../components/ObserverSection';
import ImageRevealer from '../components/ImageRevealer';
import useWindowVerticalScroll from '../utils/useWindowVerticalScroll';

const ScrollableGallery = ({ photos = [] }) => {
  const [galleryDragged, setGalleryDragged] = useState(false);
  const pageScroll = useWindowVerticalScroll();
  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef.current !== null && !galleryDragged) {
      galleryRef.current.scroll({
        left: pageScroll,
        behavior: 'smooth',
      });
    }
  });

  return (
    <ObserverSection className="gallery-container">
      <ScrollContainer
        vertical={false}
        className="edition-collection-scroll-wrapper draggable-container"
        onScroll={e => {
          if (!galleryDragged && e?.external === false) {
            setGalleryDragged(true);
          }
        }}
        innerRef={galleryRef}
      >
        <div className="edition-mobile-collection">
          {photos.map(photo => {
            const img = getImage(photo.localFile);
            return (
              <ImageRevealer key={photo.id}>
                <GatsbyImage
                  image={img}
                  className="edition-mobile-gallery-item"
                  alt=""
                />
              </ImageRevealer>
            );
          })}
        </div>
      </ScrollContainer>
      <div
        className={`drag-indicator ${galleryDragged ? 'hidden' : 'visible'}`}
      />
    </ObserverSection>
  );
};

export default ScrollableGallery;
