import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import ScrollContainer from 'react-indiana-drag-scroll';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import shuffleArray from '../utils/shuffleArray';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import NotFound from '../components/NotFound';
import ObserverSection from '../components/ObserverSection';
import EyeIcon from '../assets/icons/eye-line.svg';
import ScrollableGallery from '../components/ScrollableGallery';

const reduceInclude = (array = [], value) => {
  const reduced = array.map(el => el.slug);
  return reduced.includes(value);
};

const EditionTemplate = ({ data }) => {
  const intl = useIntl();
  const { locale } = intl;

  const { edition } = data;

  if (!edition?.editionSettings?.active) {
    return <NotFound />;
  }

  const { candidates, experts } = data;

  let displayCandidates = [];
  candidates?.nodes?.forEach(element => {
    if (
      element?.categories?.nodes &&
      element.categories.nodes.length !== 0 &&
      edition?.categories?.nodes &&
      edition.categories.nodes.length !== 0 &&
      reduceInclude(
        element.categories.nodes,
        edition?.categories?.nodes[0]?.slug
      )
    ) {
      displayCandidates = [
        ...displayCandidates,
        { ...element.candidate, id: element.id, slug: element.slug },
      ];
    }
  });

  let displayExperts = [];
  experts?.nodes?.forEach(element => {
    if (
      element?.categories?.nodes &&
      element.categories.nodes.length !== 0 &&
      edition?.categories?.nodes &&
      edition.categories.nodes.length !== 0 &&
      reduceInclude(
        element.categories.nodes,
        edition?.categories?.nodes[0]?.slug
      )
    ) {
      displayExperts = [
        ...displayExperts,
        { ...element.expertDetails, id: element.id },
      ];
    }
  });
  let gallery = [];
  candidates?.nodes?.forEach(el => {
    if (
      el?.categories?.nodes &&
      el.categories.nodes.length !== 0 &&
      edition?.categories?.nodes &&
      edition.categories.nodes.length !== 0 &&
      reduceInclude(el.categories.nodes, edition?.categories?.nodes[0]?.slug)
    ) {
      if (el.candidate.photos) {
        gallery = [...gallery, ...el.candidate.photos];
      }
    }
  });

  const shuffledGallery = shuffleArray(gallery);

  return (
    <Layout>
      <Seo
        title={
          locale === 'en' && edition?.editionSettings?.nameEn
            ? edition?.editionSettings?.nameEn
            : edition?.editionSettings?.nameFr
        }
      />
      <div className="page-title">
        {locale === 'en' && edition?.editionSettings?.nameEn
          ? edition?.editionSettings?.nameEn
          : edition?.editionSettings?.nameFr}
      </div>
      <div className="page-title-underline" />
      {(edition?.editionSettings?.aboutEn ||
        edition?.editionSettings?.aboutFr) && (
        <ObserverSection className="edition-section">
          <div className="page-subtitle sticky">
            {locale === 'fr' && edition.editionSettings.aboutTitleFr
              ? edition.editionSettings.aboutTitleFr
              : edition.editionSettings.aboutTitleEn}
          </div>
          <div
            className="edition-html-content"
            dangerouslySetInnerHTML={{
              __html:
                locale === 'en' && edition?.editionSettings?.aboutEn
                  ? edition?.editionSettings?.aboutEn
                  : edition?.editionSettings?.aboutFr,
            }}
          />
        </ObserverSection>
      )}
      {edition?.editionSettings?.customBlock?.enableSection && (
        <>
          <ObserverSection className="edition-section">
            <div className="page-subtitle sticky">
              {locale === 'fr' && edition.editionSettings.customBlock.titleFr
                ? edition.editionSettings.customBlock.titleFr
                : edition.editionSettings.customBlock.titleEn}
            </div>
            <div
              className="edition-html-content"
              dangerouslySetInnerHTML={{
                __html:
                  locale === 'fr' &&
                  edition.editionSettings.customBlock.contentFr
                    ? edition.editionSettings.customBlock.contentFr
                    : edition.editionSettings.customBlock.contentEn,
              }}
            />
          </ObserverSection>
          {edition.editionSettings.customBlock?.photos &&
            edition.editionSettings.customBlock?.photos.length !== 0 && (
              <ObserverSection
                threshold={0.5}
                className="edition-workshop-photos"
              >
                {edition.editionSettings.customBlock.photos.map(image => (
                  <GatsbyImage
                    className="gatsby-img-edition-workshop"
                    key={image.id}
                    image={getImage(image.localFile)}
                    alt=""
                  />
                ))}
              </ObserverSection>
            )}
        </>
      )}
      {(edition?.editionSettings?.workshopsEn ||
        edition?.editionSettings?.workshopsFr) && (
        <>
          <ObserverSection className="edition-section">
            <div className="page-subtitle sticky">
              {locale === 'fr' && edition.editionSettings.workshopsTitleFr
                ? edition.editionSettings.workshopsTitleFr
                : edition.editionSettings.workshopsTitleEn}
            </div>
            <div
              className="edition-html-content"
              dangerouslySetInnerHTML={{
                __html:
                  locale === 'en' && edition?.editionSettings?.workshopsEn
                    ? edition?.editionSettings?.workshopsEn
                    : edition?.editionSettings?.workshopsFr,
              }}
            />
          </ObserverSection>
          {edition?.editionSettings?.workshopsPhotos &&
            edition?.editionSettings?.workshopsPhotos.length !== 0 && (
              <ObserverSection
                threshold={0.5}
                className="edition-workshop-photos"
              >
                {edition.editionSettings.workshopsPhotos.map(image => (
                  <GatsbyImage
                    className="gatsby-img-edition-workshop"
                    key={image.id}
                    image={getImage(image.localFile)}
                    alt=""
                  />
                ))}
              </ObserverSection>
            )}
        </>
      )}
      {displayCandidates.length > 0 && (
        <>
          <ObserverSection threshold={1} className="edition-subtitle-wrapper">
            <div className="page-subtitle">
              {intl.formatMessage({ id: 'editions.candidates' })}
            </div>
          </ObserverSection>
          <div className="edition-candidates">
            {displayCandidates.map(e => (
              <a
                href={`/candidate/${e.slug}`}
                key={e.id}
                className="edition-candidate-card-wrapper"
              >
                <ObserverSection className="edition-candidate-card-wrapper-observer">
                  <div className="edition-candidate-card">
                    <GatsbyImage
                      className="gatsby-img-edition-candidate"
                      image={getImage(e.profilePicture?.localFile)}
                      alt=""
                    />
                    <div className="edition-candidate-info">
                      <div className="candidate-image-reveal" />
                      <div className="edition-candidate-name">{e.fullName}</div>
                      <div className="edition-candidate-discover">
                        {intl.formatMessage({
                          id: 'editions.discoverCandidate',
                        })}
                        <EyeIcon />
                      </div>
                    </div>
                  </div>
                </ObserverSection>
              </a>
            ))}
          </div>
        </>
      )}
      {shuffledGallery.length > 0 && (
        <>
          <ObserverSection threshold={1} className="edition-subtitle-wrapper">
            <div className="page-subtitle">
              {intl.formatMessage({ id: 'editions.gallery' })}
            </div>
          </ObserverSection>
          <ScrollableGallery photos={shuffledGallery} />
        </>
      )}
      {displayExperts.length > 0 && (
        <>
          <ObserverSection threshold={1} className="edition-subtitle-wrapper">
            <div className="page-subtitle">
              {intl.formatMessage({ id: 'editions.experts' })}
            </div>
          </ObserverSection>
          <div className="edition-experts-mobile">
            {displayExperts.map(e => (
              <ObserverSection className="edition-expert-card" key={e.id}>
                <GatsbyImage
                  className="gatsby-img-edition-candidate"
                  image={getImage(e.profilePicture?.localFile)}
                  alt=""
                />
                <div className="edition-expert-details">
                  <div className="expert-image-reveal" />
                  <div className="edition-expert-name">
                    <div>{e.name}</div>
                    <div className="expert-desc">
                      {locale === 'en' && e.descriptionEn
                        ? e.descriptionEn
                        : e.descriptionFr}
                    </div>
                  </div>
                </div>
              </ObserverSection>
            ))}
          </div>
          <div className="edition-experts-desktop">
            <ScrollContainer
              vertical={false}
              className="edition-experts-scroll-wrapper draggable-container"
            >
              <div className="edition-experts-container">
                {displayExperts.map(e => (
                  <ObserverSection className="edition-expert-card" key={e.id}>
                    <GatsbyImage
                      className="gatsby-img-edition-candidate"
                      image={getImage(e.profilePicture?.localFile)}
                      alt=""
                    />
                    <div className="edition-expert-details">
                      <div className="expert-image-reveal" />
                      <div className="edition-expert-name">
                        <div>{e.name}</div>
                        <div className="expert-desc">
                          {locale === 'en' && e.descriptionEn
                            ? e.descriptionEn
                            : e.descriptionFr}
                        </div>
                      </div>
                    </div>
                  </ObserverSection>
                ))}
              </div>
            </ScrollContainer>
          </div>
        </>
      )}
    </Layout>
  );
};

export default EditionTemplate;

export const pageQuery = graphql`
  query editionById($id: String!) {
    edition: wpEdition(id: { eq: $id }) {
      editionSettings {
        workshopsTitleFr
        workshopsTitleEn
        aboutTitleFr
        aboutTitleEn
        aboutEn
        aboutFr
        active
        descriptionEn
        descriptionFr
        fieldGroupName
        nameEn
        nameFr
        url
        workshopsEn
        workshopsFr
        workshopsPhotos {
          id
          localFile {
            url
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        customBlock {
          contentEn
          contentFr
          enableSection
          titleEn
          titleFr
          photos {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      categories {
        nodes {
          slug
        }
      }
    }
    candidates: allWpCandidate {
      nodes {
        slug
        categories {
          nodes {
            slug
          }
        }
        id
        candidate {
          photos {
            id
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, height: 512)
              }
            }
          }
          fullName
          profilePicture {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 600)
              }
            }
            altText
          }
        }
      }
    }
    experts: allWpExpert {
      nodes {
        id
        categories {
          nodes {
            slug
          }
        }
        expertDetails {
          descriptionEn
          descriptionFr
          fieldGroupName
          name
          profilePicture {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 600)
              }
            }
          }
        }
      }
    }
  }
`;
